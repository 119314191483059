<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="align-items-center col d-flex justify-content-end text-right">
          <base-button v-if="selectedOrdersCount > 0" type="success" size="sm" @click="handleExportSelected">Export selected to CSV</base-button>
          <base-button v-if="selectedOrdersCount > 0" type="danger" size="sm" @click="handleBillSelected">Bill selected</base-button>
          <base-button type="info" size="sm" @click="handleBillAll">Bill all orders</base-button>

          <base-dropdown class="dropdown" position="right">
            <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
            </a>
            <template>
              <a v-if="!showMultiple" class="dropdown-item" href="#" @click="(e) => { e.preventDefault(); setMultiple(true) }">Edit</a>
              <a v-else class="dropdown-item" href="#" @click="(e) => { e.preventDefault(); setMultiple(false) }">Close Edit</a>
            </template>
          </base-dropdown>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush" :class="type === 'dark' ? 'table-dark': ''"
        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'" tbody-classes="list" :data="orders && orders.data && orders.data.docs">
        <template slot="columns">
          <th v-if="showMultiple">
            <base-checkbox v-model="checkAll" />
          </th>
          <th>Kundennr</th>
          <th>Company</th>
          <th>Datum</th>
          <th>Zeit</th>
          <th>Farbe</th>
          <th>Druckseite</th>
          <th>Seitenzahl</th>
          <th>Brief</th>
          <th>Brief_matchcode</th>
          <th>Aktion</th>
        </template>

        <template slot-scope="{row}">
          <td v-if="showMultiple">
            <base-checkbox :checked="isSelected(row)" @input="toggleRowSelect(row)" :disabled="row.billedAt" />
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.userId.customerNo || '-' }}</span>
              </div>
            </div>
          </th>
          <td>{{ row.userId.company || '-' }}</td>
          <td>{{ row.createdAt | formatDate }}</td>
          <td>{{ row.createdAt | formatTime }}</td>
          <td>{{ row.colormode }}</td>
          <td>
            <span v-if="row.duplex">2-seitig</span>
            <span v-else>1-seitig</span>
          </td>
          <td>{{ calculatePageCount(row) }}</td>
          <td>{{ row.letterType ? row.letterType.product : '' }} <span v-if="row.briefType">{{ row.briefType }}</span></td>
          <td>{{ row.letterType ? row.letterType.matchcode : '' }}</td>
          <td>
            <base-button type="success" size="sm" @click="handleDownload(row)">CSV</base-button>
            <base-button type="danger" size="sm" :disabled="row.billedAt" @click="handleBill(row)">
              {{ row.billedAt ? 'abgerechnet' : 'abrechnen' }}
            </base-button>
          </td>
        </template>
      </base-table>
    </div>
    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination v-if="orders && orders.data" :pageCount="orders.data.totalPages" :value="orders.data.page" @input="(val) => $emit('paginate', val)"></base-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'billing-table',
  props: {
    type: {
      type: String
    },
    status: {
      type: String
    },
    title: String,
    orders: Object
  },
  data() {
    return {
      showMultiple: false,
      checkAll: false,
      selectedOrders: {},
      loadingDownloadCSV: false,
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    selectedOrdersCount () {
      return Object.keys(this.selectedOrders).length
    }
  },
  watch: {
    status() {
      this.showMultiple = false
      this.checkAll = false
      this.selectedOrders = {}
    },
    checkAll (val) {
      if (val) {
        // check all
        let newSelected = {}
        for (const order of this.orders.data.docs) {
          if (!order.billedAt) {
            newSelected[order._id] = true
          }
        }
        this.selectedOrders = newSelected
      } else {
        // uncheck all
        this.selectedOrders = {}
      }
    }
  },
  methods: {
    setMultiple (val) {
      this.showMultiple = val
      if (!val) {
        this.selectedOrders = {}
        this.checkAll = false
      }
    },
    calculatePageCount (row) {
      let count = 0
      if (row.document) {
        count += row.document.pageCount
      }
      if (row.attachments && row.attachments.length) {
        count += row.attachments.map(a => a.pageCount).reduce((a, b) => a + b, 0)
      }
      return count
    },
    calculatePagePrintCount (row) {
      let count = this.calculatePageCount(row);
      if (row.duplex) {
        count = Math.ceil(count/2);
      }
      return count
    },
    toggleRowSelect (row) {
      const orderId = row._id

      if (orderId in this.selectedOrders) {
        // unselect order
        // eslint-disable-next-line no-unused-vars
        const { [orderId]: value, ...remaining } = this.selectedOrders
        this.selectedOrders = remaining
      } else {
        // select order
        this.selectedOrders = {
          ...this.selectedOrders,
          [orderId]: true
        }
      }
    },
    isSelected (row) {
      return row._id in this.selectedOrders
    },
    msieversion () {
      var ua = window.navigator.userAgent
      var msie = ua.indexOf("MSIE ")
      // eslint-disable-next-line no-useless-escape
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true
      }
      return false
    },
    getHeaderCSV () {
      let CSV = ''
      let row = ''
      const headers = ['Kundennr', 'Kunden-UPOC', 'Company', 'Datum', 'Zeit', 'Farbe', 'Druckseite', 'Seitenzahl', 'Blätterzahl', 'AuftragsUPOC', 'Brief', 'Brief_matchcode']
      for (const header of headers) {
        row += header + ';'
      }
      row = row.slice(0, -1)
      CSV += row + '\r\n'
      return CSV
    },
    getCSVFromOrders (orders) {
      let CSV = ''

      const orderData = orders.map(o => ({
        Kundennr: o.userId.customerNo,
        'Kunden-UPOC': o.customerUpoc || null,
        Company: o.userId.company,
        'Datum & Zeit': o.createdAt,
        Farbe: o.colormode,
        Druckseite: o.duplex ? '2-seitig' : '1-seitig',
        Seitenzahl: this.calculatePageCount(o),
        Blätterzahl: this.calculatePagePrintCount(o),
        Abgerechnet: !!o.billedAt,
        AuftragsUPOC: o.upocId ? o.upocId.code : null,
        Brief: `${o.letterType ? o.letterType.product + ' ' : ''}${o.briefType ? o.briefType : ''}`,
        Brief_matchcode: o.letterType ? o.letterType.matchcode : null
      }))

      for (let i = 0; i < orderData.length; i++) {
        let row = ''
        for (let index in orderData[i]) {
          let arrValue = orderData[i][index] == null ? "" : '="' + orderData[i][index] + '"'
          row += arrValue + ';'
        }
        row.slice(0, row.length - 1)
        CSV += row + '\r\n'
      }
      return CSV
    },
    handleDownload (row) {
      row.isDownloading = true
      const CSV = this.getHeaderCSV() + this.getCSVFromOrders([row])
      this.downloadFileFromCSV (CSV)
      row.isDownloading = false
    },
    handleExportSelected () {
      let CSV = this.getHeaderCSV()
      Object.keys(this.selectedOrders).map((orderId) => {
        const order = this.orders.data.docs.find(doc => doc._id === orderId)
        if (order) {
          CSV += this.getCSVFromOrders([order])
        }
      })
      this.downloadFileFromCSV (CSV)
      this.setMultiple(false)
    },
    handleDownloadAllCSV () {
      this.loadingDownloadCSV = true

      // Retrieve all billed orders with no pagination
      const params = {
        query: {
          $or: [
            { status: 'billed' },
            { status: 'printed' }
          ]
        },
        includeManaged: true,
        pagination: false
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        params.isPrintedBetween = [
          this.dateRange.startDate,
          this.dateRange.endDate
        ]
      }

      this.$store.dispatch('order/fetchAllOrders', params)
        .then(async ({ data }) => {
          let CSV = this.getHeaderCSV()

          data.docs.forEach((order) => {
            CSV += this.getCSVFromOrders([order])
          })
          this.downloadFileFromCSV(CSV)
        })
        .catch(({error}) => {
          this.$notify({type: 'danger', message: error.http_message})
        })
        .finally(() => {
          this.loadingDownloadCSV = false
        })
    },
    downloadFileFromCSV (CSV) {
      const fileName = `${new Date().getTime().toString()}.csv`
      if(this.msieversion()) {
        const IEwindow = window.open()
        IEwindow.document.write('sep=,\r\n' + CSV)
        IEwindow.document.close()
        IEwindow.document.execCommand('SaveAs', true, fileName)
        IEwindow.close()
      } else {
        const uri = 'data:application/csv;charset=utf-8,' + escape(CSV)
        const link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    handleBill (row) {
      this.$confirm(
        {
          message: `Möchten Sie den Auftrag abrechnen?`,
          button: {
            no: 'Nein',
            yes: 'Ja'
          },
          callback: confirm => {
            if (confirm) {
              row.isBilling = true
              this.$store.dispatch('order/billOrder', row)
                .then(() => {
                  this.$notify({type: 'success', message: 'Auftrag abgerechnet'})
                })
                .catch(({error}) => {
                  this.$notify({type: 'danger', message: error.http_message})
                })
                .finally(() => {
                  row.isBilling = false
                })
            }
          }
        }
      )
    },
    handleBillSelected () {
      this.$confirm(
        {
          message: `Bill selected orders?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              Object.keys(this.selectedOrders).map((orderId) => {
                const order = this.orders.data.docs.find(doc => doc._id === orderId)
                if (order) {
                  this.$store.dispatch('order/billOrder', order)
                    .then(() => {
                      this.$notify({type: 'success', message: 'Order billed'})
                    })
                    .catch(({error}) => {
                      this.$notify({type: 'danger', message: error.http_message})
                    })
                }
              })
              this.setMultiple(false)
            }
          }
        }
      )
    },
    handleBillAll () {
      this.$confirm(
        {
          message: `Bill all orders?`,
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              // Retrieve all orders to be billed
              const params = {
                query: { status: 'printed' },
                pagination: false
              }
              this.$store.dispatch('order/fetchAllOrders', params)
                .then(async ({ data }) => {
                  if (data.docs?.length) {
                    // Bill all orders
                    const billAllPromises = data.docs.map(doc => this.$store.dispatch('order/billOrder', doc))
                    const results = await Promise.all(billAllPromises.map(p => p.catch(e => e)))
                    const validResults = results.filter(result => !(result instanceof Error));
                    this.$notify({type: 'success', message: `${validResults.length} orders have been billed`})

                    // Download billed order's csv
                    let CSV = this.getHeaderCSV()
                    data.docs.forEach((order) => {
                      CSV += this.getCSVFromOrders([order])
                    })
                    this.downloadFileFromCSV(CSV)
                  }
                })
            }
          }
        }
      )
    }
  }
}
</script>

<style>
.reportrange-text {
  height: 32px;
}
</style>
